var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v(" 冗余库存调配TurnOver计算公式 ")]),_c('div',{staticClass:"txt_size_14"},[_vm._v("1. 库存缺货")]),_c('Formula',{attrs:{"turn-over-params":{
      turnOverType: 'stockoutStockProvision',
      turnOverTypeName: '库存缺货',
    },"turn-over-type-data":_vm.turnOverList.stockoutStockProvision},on:{"getTurnOver":_vm.getTurnOver}}),_c('div',{staticClass:"txt_size_14"},[_vm._v("2. 库存冗余")]),_c('Formula',{attrs:{"turn-over-params":{
      turnOverType: 'redundantStockProvision',
      turnOverTypeName: '库存冗余',
    },"turn-over-type-data":_vm.turnOverList.redundantStockProvision},on:{"getTurnOver":_vm.getTurnOver}}),_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("补货TurnOver计算公式")]),_c('div',{staticClass:"txt_size_14"},[_vm._v("1. 海外调拨")]),_c('Formula',{attrs:{"turn-over-params":{
      turnOverType: 'overseaAllcationReplenish',
      turnOverTypeName: '海外调拨补货',
    },"turn-over-type-data":_vm.turnOverList.overseaAllcationReplenish},on:{"getTurnOver":_vm.getTurnOver}}),_c('div',{staticClass:"txt_size_14"},[_vm._v("2. 国内快船")]),_c('Formula',{attrs:{"turn-over-params":{
      turnOverType: 'cnExpressReplensish',
      turnOverTypeName: '国内快船补货',
    },"turn-over-type-data":_vm.turnOverList.cnExpressReplensish},on:{"getTurnOver":_vm.getTurnOver}}),_c('div',{staticClass:"txt_size_14"},[_vm._v("3. 国内普船")]),_c('Formula',{attrs:{"turn-over-params":{
      turnOverType: 'cnCarrierReplensish',
      turnOverTypeName: '国内普船补货',
    },"turn-over-type-data":_vm.turnOverList.cnCarrierReplensish},on:{"getTurnOver":_vm.getTurnOver}}),_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("翻单TurnOver计算公式")]),_c('div',{staticClass:"txt_size_14"},[_vm._v("1. 国内快船")]),_c('Formula',{attrs:{"turn-over-params":{
      turnOverType: 'cnExpressReorder',
      turnOverTypeName: '国内快船翻单',
    },"turn-over-type-data":_vm.turnOverList.cnExpressReorder},on:{"getTurnOver":_vm.getTurnOver}}),_c('div',{staticClass:"txt_size_14"},[_vm._v("2. 国内普船")]),_c('Formula',{attrs:{"turn-over-params":{
      turnOverType: 'cnCarrierReorder',
      turnOverTypeName: '国内普船翻单',
    },"turn-over-type-data":_vm.turnOverList.cnCarrierReorder},on:{"getTurnOver":_vm.getTurnOver}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }