<template>
  <div>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      label-width="0"
      class="demo-ruleForm"
    >
      <div class="box_con">
        <div class="box_sel">
          <div v-for="(item, index) in ruleForm.queryArr" :key="item.index">
            <el-form-item
              :prop="'queryArr.' + index + '.value'"
              :rules="[{ required: true, message: '请选择' }]"
              style="display: inline-block"
            >
              <el-select
                v-model="item.value"
                :disabled="!isEdit"
                placeholder="请选择"
                style="po"
                filterable
              >
                <el-option
                  v-for="list in options"
                  :key="list.value"
                  :label="list.label"
                  :value="list.val"
                />
              </el-select>
            </el-form-item>

            <div v-if="isEdit && index > 0" class="box_rm">
              <i
                class="el-icon-remove-outline box_remove"
                @click="remove(index)"
              />
            </div>
            <div v-if="item.value == 'customDays'" class="box_number">
              <el-form-item
                :prop="'queryArr.' + index + '.days'"
                :rules="[
                  { required: true, message: '请输入' },
                  { validator: validatePass, trigger: 'blur' },
                ]"
              >
                <el-input-number
                  v-model="item.days"
                  :disabled="!isEdit"
                  placeholder="请输入"
                  style="width: 140px"
                  controls-position="right"
                  :min="-999999"
                  :max="999999"
                  :precision="0"
                />天
                <!-- <template slot="append">天</template>
                </el-input> -->
              </el-form-item>
            </div>
            <i
              v-if="index < 14 && isEdit"
              class="el-icon-circle-plus add"
              @click="add(index)"
            />
            <i
              v-if="
                index < 14 && !isEdit && index != ruleForm.queryArr.length - 1
              "
              class="el-icon-plus add_show"
            />
          </div>
        </div>
        <div>
          <el-button
            v-if="!isEdit"
            type="text"
            icon="el-icon-edit"
            @click="handleEdit"
          >编辑</el-button>
          <el-button
            v-else
            type="text"
            icon="el-icon-collection"
            :loading="loading"
            @click="submitForm('ruleForm')"
          >保存</el-button>

          <el-button
            v-if="isEdit"
            type="text"
            @click="isEdit = false;init();"
          >取消</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { saveturnover } from '@/api/supply'
import { deepClone } from '@/utils'
import { handleBreadTitle, trackDialogEvent } from '@/utils/monitor'

export default {
  components: {},
  mixins: [commodityInfoDict],
  props: {
    turnOverParams: {
      type: Object,
      default: () => {}
    },
    turnOverTypeData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      initData: {},
      ruleForm: {
        queryArr: [
          {
            index: 0,
            days: '',
            value: ''
          }
        ]
      },
      options: [],
      rules: {},
      isEdit: false,
      loading: false
    }
  },
  watch: {
    turnOverTypeData: {
      deep: true,
      handler(val) {
        if (val) {
          this.initData = val
          this.init()
          // if (!Array.isArray(data?.turnOverFormulaJson)) {
          //   this.ruleForm.queryArr = JSON.parse(data.turnOverFormulaJson) || [{
          //     index: 0,
          //     days: '',
          //     value: ''
          //   }]
          // }
        }
      }
    }
  },
  mounted() {
    this.options = this._getAllCommodityDict('SCM_TURN_OVER_CALC_ITEM') || []
  },
  methods: {

    handleEdit() {
      this.isEdit = true
      trackDialogEvent(handleBreadTitle(this.$route) + '编辑')
    },
    init() {
      const data = deepClone(this.initData)
      this.ruleForm.queryArr = data?.turnOverFormulaJson && JSON.parse(data.turnOverFormulaJson) || [{
        index: 0,
        days: '',
        value: ''
      }]
      if (!Object.keys(data).length) {
        this.resetForm('ruleForm')
      }
    },
    validatePass(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入'))
      } else {
        if (!/^-?\d+$/.test(value)) {
          callback(new Error('请输入整数!'))
        }
        callback()
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          try {
            this.loading = true
            const { queryArr } = this.ruleForm
            let str = ''

            queryArr.map((item) => {
              if (item.value === 'customDays') {
                str += '(' + (item.days < 0 ? '0' : '') + item.days + ') +'
              } else {
                str += '${' + item.value + '} +'
              }
            })
            if (str.endsWith('+')) {
              str = str.slice(0, -1) // 去掉最后一个字符
            }
            const params = {
              ...this.turnOverParams,
              turnOverFormulaJson: JSON.stringify(queryArr),
              turnOverFormula: str,
              id: this.turnOverTypeData.id
            }

            await saveturnover(params)
            this.isEdit = false
            this.loading = false
            this.$emit('getTurnOver')
          } finally {
            this.loading = false
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    clearForm(formName) {
      this.$refs[formName]?.clearValidate()
    },
    add(index) {
      if (this.ruleForm.queryArr.length > 14) {
        this.$message({
          message: '单个公式，不得超出15个运算项！',
          type: 'warning'
        })
        return
      }
      this.ruleForm.queryArr.splice(index + 1, 0, {
        index: index + 1,
        days: '',
        value: ''
      })
    },
    remove(index) {
      this.ruleForm.queryArr.splice(index, 1)
    }
  }
}
</script>
<style lang="scss" scope>
.box_con {
  padding: 15px;
  width: 100%;
  min-height: 80px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
}
.add {
  font-size: 20px;
  color: #01a6f0;
  margin: 0 15px;
}
.add_show {
  margin: 0 15px;
}

.box_rm {
  display: inline-block;
  position: absolute;
}
.box_remove {
  position: relative;
  right: 8px;
  top: -14px;
}
.box_number {
  // margin-left: 15px;
  display: inline-block;
}
.box_sel {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  div {
    // margin-bottom: 15px;
  }
}
</style>
