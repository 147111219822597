<template>
  <div class="app-container">
    <el-divider content-position="left">
      冗余库存调配TurnOver计算公式
    </el-divider>
    <div class="txt_size_14">1. 库存缺货</div>
    <Formula
      :turn-over-params="{
        turnOverType: 'stockoutStockProvision',
        turnOverTypeName: '库存缺货',
      }"
      :turn-over-type-data="turnOverList.stockoutStockProvision"
      @getTurnOver="getTurnOver"
    />
    <div class="txt_size_14">2. 库存冗余</div>
    <Formula
      :turn-over-params="{
        turnOverType: 'redundantStockProvision',
        turnOverTypeName: '库存冗余',
      }"
      :turn-over-type-data="turnOverList.redundantStockProvision"
      @getTurnOver="getTurnOver"
    />
    <el-divider content-position="left">补货TurnOver计算公式</el-divider>
    <div class="txt_size_14">1. 海外调拨</div>
    <Formula
      :turn-over-params="{
        turnOverType: 'overseaAllcationReplenish',
        turnOverTypeName: '海外调拨补货',
      }"
      :turn-over-type-data="turnOverList.overseaAllcationReplenish"
      @getTurnOver="getTurnOver"
    />
    <div class="txt_size_14">2. 国内快船</div>
    <Formula
      :turn-over-params="{
        turnOverType: 'cnExpressReplensish',
        turnOverTypeName: '国内快船补货',
      }"
      :turn-over-type-data="turnOverList.cnExpressReplensish"
      @getTurnOver="getTurnOver"
    />
    <div class="txt_size_14">3. 国内普船</div>
    <Formula
      :turn-over-params="{
        turnOverType: 'cnCarrierReplensish',
        turnOverTypeName: '国内普船补货',
      }"
      :turn-over-type-data="turnOverList.cnCarrierReplensish"
      @getTurnOver="getTurnOver"
    />

    <el-divider content-position="left">翻单TurnOver计算公式</el-divider>
    <div class="txt_size_14">1. 国内快船</div>
    <Formula
      :turn-over-params="{
        turnOverType: 'cnExpressReorder',
        turnOverTypeName: '国内快船翻单',
      }"
      :turn-over-type-data="turnOverList.cnExpressReorder"
      @getTurnOver="getTurnOver"
    />
    <div class="txt_size_14">2. 国内普船</div>
    <Formula
      :turn-over-params="{
        turnOverType: 'cnCarrierReorder',
        turnOverTypeName: '国内普船翻单',
      }"
      :turn-over-type-data="turnOverList.cnCarrierReorder"
      @getTurnOver="getTurnOver"
    />
  </div>
</template>
<script>
import Formula from './components/Formula'
import {
  getTurnOverList
} from '@/api/supply'
export default {
  components: {
    Formula
  },
  data() {
    return {
      turnOverList: {
        redundantStockProvision: {
          id: '',
          turnOverFormulaJson: []
        },
        cnCarrierReplensish: {
          id: '',
          turnOverFormulaJson: []
        },
        cnExpressReplensish: {
          id: '',
          turnOverFormulaJson: []
        },
        cnCarrierReorder: {
          id: '',
          turnOverFormulaJson: []
        },
        cnExpressReorder: {
          id: '',
          turnOverFormulaJson: []
        },
        overseaAllcationReplenish: {
          id: '',
          turnOverFormulaJson: []
        }
      }
    }
  },
  mounted() {
    this.getTurnOver()
  },
  methods: {
    async getTurnOver() {
      const {
        datas
      } = await getTurnOverList()
      console.log('%c Line:71 🌽 datas', 'color:#2eafb0', datas)
      if (Array.isArray(datas)) {
        datas.forEach(item => {
          this.turnOverList[item.turnOverType] = item
        })
      }
      console.log('%c Line:108 🌶 item', 'color:#ea7e5c', this.turnOverList)
    }
  }
}
</script>
<style lang="scss" scope>
.box_con {
  padding: 15px;
  width: 100%;
  min-height: 80px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
}
.add {
  font-size: 20px;
  color: #01a6f0;
  margin: 0 15px;
}

.box_rm {
  display: inline-block;
  position: absolute;
}
.box_remove {
  position: relative;
  right: 8px;
  top: -14px;
}
.box_number {
  margin-left: 15px;
  display: inline-block;
}
.box_sel {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  > div {
    margin-bottom: 15px;
  }
}
.txt_size_14 {
  font-size: 14px;

  margin: 20px 40px 6px;
}
</style>
